import React, { useEffect } from "react"

import ParticlesJS from "./particles"
import Reveal from "react-reveal/Reveal"

import SEO from "../SEO"

import Burger from "../navigation/burger"
import Navbar from "../navigation/navbar"
import Social from "../social/social"

import "../../styles/styles.scss"

const Layout = props => {
  useEffect(() => {
    const checkScroll = () => {
      if (props.children.slice(5).length === 0) {
        document.getElementById("arrow").style.visibility = "hidden"
      }
    }
    checkScroll()
  })
  return (
    <div className="main">
      <SEO pathname={props.children[1].props.children} />
      <ParticlesJS />
      <Burger />
      <div className="main-image">{props.children[0]}</div>
      <div className="main-content">
        <Navbar />
        <div className="content">
          <Reveal duration={400}>
            <section className="introduction">
              {props.children.slice(1, 4)}
              <Social />
              <div className="introduction-links">{props.children[4]}</div>
              <div id="arrow" className="arrow"></div>
            </section>
          </Reveal>
          {props.children.slice(5)}
        </div>
      </div>
    </div>
  )
}

export default Layout
