import React from "react"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import GitHub from "../../media/assets/socials/github.svg"
import LinkedIn from "../../media/assets/socials/linkedin.svg"
import Instagram from "../../media/assets/socials/instagram.svg"
import YouTube from "../../media/assets/socials/youtube.svg"

const Social = () => {
  return (
    <ul className="social-list">
      <li>
        <OutboundLink
          className="social-list-item"
          href="https://github.com/lauirvin"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={GitHub} alt="github" />
        </OutboundLink>
      </li>
      <li>
        <OutboundLink
          className="social-list-item"
          href="https://linkedin.com/in/lauirvin/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={LinkedIn} alt="linkedin" />
        </OutboundLink>
      </li>
      <li>
        <OutboundLink
          className="social-list-item"
          href="https://instagram.com/irvin.dev/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Instagram} alt="instagram" />
        </OutboundLink>
      </li>
      <li>
        <OutboundLink
          className="social-list-item"
          href="https://youtube.com/user/lauirvin98"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={YouTube} alt="youtube" />
        </OutboundLink>
      </li>
    </ul>
  )
}

export default Social
